<template>
  <div>
    <el-dialog
      width="648px"
      title="选择提交活动作品的方式"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
    >
      <div class="center">
        <div>
          <p>提交新作品参加活动</p>
          <!-- <button @click="jumproute">创建新的作品</button> -->
          <el-dropdown @command="handleCommand">
            <el-button type="primary">
              创建新作品<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.id"
                v-for="item in demo"
                :key="item.name"
                ><img
                  style="
                    width: 17px;
                    height: 17px;
                    margin-right: 7px;
                    vertical-align: middle;
                  "
                  :src="item.image"
                /><span style="vertical-align: middle">{{
                  item.name
                }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="isWorksList">
          <p>选择从已发布作品中选择投稿作品</p>
          <el-dropdown @command="command">
            <el-button type="primary">
              选择作品<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.id"
                v-for="item in demo"
                :key="item.name"
                ><img
                  style="
                    width: 17px;
                    height: 17px;
                    margin-right: 7px;
                    vertical-align: middle;
                  "
                  :src="item.image"
                /><span style="vertical-align: middle">{{
                  item.name
                }}</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <button @click="select">选择作品</button> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const demolist = [{ id: 2, image: "/assets/8.png", name: '发布歌曲' }, { id: 1, image: "/assets/10.png", name: '发布词曲' }];
export default {
  created () {
     this.toggle()
  },
  update (

  ) {
    console.log(this.contributionsType)
  },
  data () {
    return {
      dialogTableVisible: false,
      //   活动列表
      demo: []
    }
  },
  computed: {
    // 获取是否又可选歌曲
    isWorksList () {
      return this.$store.state.Songdetails.isWorksList
    },
    //获取当前身份
    webLoginType () {
      return this.$store.state.user.webLoginType
    },
    // 获取征稿类型
    contributionsType () {
      return this.$store.state.Songdetails.contributionsType
    },

  },
  methods: {
    // 路由跳转
    jumproute () {
      // this.$router.push({ path: '/publish', query: { activity: true } })
      this.$router.push({ path: '/release/0'})
    },
    handleCommand (command) {
      if (command == 2) {
        // this.$router.push({ path: '/publish', query: { activity: true } })
        this.$router.push({ path: '/release/0'})
      }
      if (command == 1) {
        this.$router.push({ path: '/demo/0', query: { activity: true } })
      }
    },
    command (
      command
    ) {
      this.$router.push({ path: '/electsong', query: { radio: command } })
    },
    toggle(){
          this.demo=[]
        if (this.webLoginType == 1) {
      this.demo.push(demolist[0])
    } else {
      if (this.contributionsType != undefined) {
        let arr = this.contributionsType.split(',')
        if (arr.length > 1) {
          this.demo = demolist
        } else if (arr[0] == 1) {
          this.demo.push(demolist[1])
        } else if (arr[0] == 2) {
          this.demo.push(demolist[0])
        }
      }

    }
    }
  },
  watch: {
    'contributionsType': function (newval) {
      console.log(newval,123)
      let arr = this.contributionsType.split(',')
      if (arr.length > 1 && this.webLoginType==0) {
        this.demo = demolist
      } else if (arr[0] == 1 && this.webLoginType==0) {
        this.demo.push(demolist[1])
      } else if (arr[0] == 2 ) {
        this.demo.push(demolist[0])
      }
    },
    'webLoginType': function (val) {
        console.log(val)
          this.toggle()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0px;
  padding: 0;
  padding-top: 47px;
}
::v-deep .el-dialog {
  height: 394px;
}
::v-deep .el-dialog__body {
  padding: 0;
  .center {
    height: 323px;
    padding-top: 30px;
    box-sizing: border-box;

    div {
      text-align: center;

      p {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #333333;
        letter-spacing: 0px;
      }
      button {
        margin-top: 16px;
        border: none;
        width: 130px;
        height: 40px;
        opacity: 1;
        background: #ff004d;
        border-radius: 2px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #ffffff;
      }
    }
    > div:nth-child(2) {
      margin-top: 50px;
    }
  }
}
</style>
