<template>
  <div class="index">
<div
    class="fuzzybackground"
      v-html="detailImg"
    ></div>
    <div
    class="activity-html"
      v-html="detailImg"
    ></div>
    <div class="btn" v-if="flagbtn">
      <img v-if="!falg && status == 2" :src="img" alt="" @click="fn" />
      <img v-else :src="img" alt="" />
    </div>
    <SpringFrame ref="frame" />
  </div>
</template>

<script>
import SpringFrame from '../components/SpringFrame.vue'
import { activity_detail } from '../api/index';
export default {
  components: {
    SpringFrame
  },
  async created () {
    await activity_detail(this.$route.params.id).then(res => {
      // console.log(res)
      this.detailImg = res.data.detailImg
      let p = {}
      for (var i in res.data) {
        if (i != 'detailImg') {
          p[i] = res.data[i]
        }
      }
      this.$store.commit('FNDETAILS', p)
    })
    if (this.status == 1) {
      this.flagbtn = false;
    } else {
      this.flagbtn = true;
    }
    if (this.status == 2) {
      if (this.isSign) {
        this.falg = true
        this.img = this.$store.state.Songdetails.signDoneImg
      } else {
        this.falg = false
        this.img = this.$store.state.Songdetails.signUpImg
      }
    }
    if (this.status == 3) {
      if (this.isSign) {
        this.falg = true
        this.img = this.$store.state.Songdetails.signDoneImg
      } else {

        this.img = this.$store.state.Songdetails.regEndImg
      }

    }
    if (this.status == 4 || this.status == 5) {
      this.img = this.$store.state.Songdetails.activityEndImg
    }
  },
  computed: {

    // 获取活动类型
    contributionsType () {
      return this.$store.state.Songdetails.contributionsType
    },
    // 获取当前身份
    webLoginType () {
      return this.$store.state.user.webLoginType
    },
    status () {
      return this.$store.state.Songdetails.status
    },
    // 是否已报名
    isSign () {
      return this.$store.state.Songdetails.isSign
    },
    type () {
      return this.$store.state.Songdetails.type
    }
  },
  data () {
    return {
      falg: false,
      img: '',
      flagbtn: '',
      // 报名背景
      detailImg: ''
    };
  },
  methods: {
    // 立即报名
    fn () {
        if(this.$store.state.user===null) {
            this.$popup({msg:'请先登录再报名'})
            setTimeout(() =>{
                this.$router.replace('/')
            },100)
            return
        }
      let arrtype = this.contributionsType.split(',')
      //   判断是否为纯词曲
      if (arrtype.length == 1 && arrtype[0] == '1' && this.webLoginType == 1) {
        this.$popup({
          title: '提示',
          msg: '机构暂时无法参加词曲活动',
          type: 'warning'
        });
        return;
      }

      this.$refs.frame.dialogTableVisible = true;
    }
  }
}
</script>

<style lang="scss">
.fuzzybackground{
    position:absolute;
    z-index: 1;
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: inherit;
    opacity: 0.4;
    filter: blur(30px);
  
   p {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.activity-html {
    position:relative;
    z-index: 3;
    min-height:100vh;
  p {
    margin: auto;
    max-width: 750px;
    display: flex;
    flex-direction: column;
  }
}
</style>
<style scoped lang="scss">
@import "../styles/initialization.scss";
.index {
  background:black;
  position: relative;
  text-align: center;
  overflow: hidden;   
  .btn {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 80px;
    bottom: 100px;
    img {
      width: 300px;
      height: 100%;
    }
  }
}
</style>
